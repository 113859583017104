import './App.css';
import companyLogo from './GetPrepGo_Horizontal_RGB.png';
import { Container, Navbar, ProgressBar, Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import { ChevronDoubleRight, ChevronRight } from 'react-bootstrap-icons';

const url = process.env.REACT_APP_API_URL;

// const resource = {
// 	"version": 1,
// 	"questions":
// 		[{
// 			"question_number": 1,
// 			"question": "How confident are you in your ability to look for work?",
// 			"factor": "Confidence",
// 			"lowest": "Not at all",
// 			"highest": "Very confident",
// 			"comments": ["Your confidence will grow when you have a clear plan. Now is a good time to think about what steps might help.", "It’s good to have that confidence. Think about what gives you that confidence and remind yourself each day."],
// 			"scores": "[1,2,3],[4,5]"
// 		},
// 		{
// 			"question_number": 2,
// 			"question": "How committed are you to doing job search activities?",
// 			"factor": "Motivation",
// 			"lowest": "Not at all",
// 			"highest": "Very committed",
// 			"comments": ["Before you increase your commitment, talk to your employment consultant. Once you have a plan, then the activities will be relevant to you.", "Your answer shows that you have some commitment. Now is the time to do job search activities. They will increase your confidence and motivation.", "Congrats. It’s good to know that each time you do these activities, you increase your chance of success."],
// 			"scores": "[1],[2,3],[4,5]"
// 		},
// 		{
// 			"question_number": 3,
// 			"question": "Think about the people in your life. How supportive will they be as you look for work?",
// 			"factor": "Confidence",
// 			"lowest": "Not at all",
// 			"highest": "Very confident",
// 			"comments": ["That happens. It’s why your employment consultant is on your team. Connecting with others is also one of the key benefits of getting a job.", "Having people who support you is important. It improves how we feel and it’s also a key benefit of getting a job."],
// 			"scores": "[1,2,3],[4,5]"
// 		},
// 		{
// 			"question_number": 4,
// 			"question": "Do you believe that your skills will help you find work?",
// 			"factor": "Confidence",
// 			"lowest": "Not at all",
// 			"highest": "Very supportive",
// 			"comments": ["You will be surprised. Most people have hidden strengths, so start to notice these and discover how they can help.", "It sounds like you have some idea about the skills you offer. Many people also have hidden strengths that can help them even more.", "It seems like you have a clear idea about the skills you offer."],
// 			"scores": "[1,2],[3],[4,5]"
// 		},
// 		{
// 			"question_number": 5,
// 			"question": "Have you started to think about your job options and goals?",
// 			"factor": "Motivation",
// 			"lowest": "Not at all",
// 			"highest": "A lot",
// 			"comments": ["The good news is you will discover this with your employment consultant. Why not start now?", "Thinking about your job options and goals means you have a better chance of success. Turn your ideas into action.", "Your focus means you are determined. This is a key strength that will help with your next steps."],
// 			"scores": "[1],[2,3],[4,5]"
// 		},
// 		{
// 			"question_number": 6,
// 			"question": "Can you see yourself working sometime soon? Is working something you would like to do?",
// 			"factor": "Motivation",
// 			"lowest": "Not at all",
// 			"highest": "Very motivated",
// 			"comments": ["It’s something to think about. And by being open minded, maybe you’ll make the right choice for you.", "Maybe you haven't decided to start actively looking yet. When you do, your employment consultant can help.", "That's good. It's great you’re so committed. Imagine what’s possible."],
// 			"scores": "[1],[2,3],[4,5]"
// 		}]
// }

function App() {
	const [progress, setProgress] = useState(0);
	const [index, setIndex] = useState(0);
	const [response, setResponse] = useState([]);
	const [currSliderVal, setCurrSliderVal] = useState(-1);
	const [page, setPage] = useState(-1);
	const [warnExit, setWarnExit] = useState(false);
	const [script, setScript] = useState();
	const [showNextButton, setShowNextButton] = useState(false);
	const [hideThumb, setHideThumb] = useState(true);
	const [enableSubmit, setEnableSubmit] = useState(false);

	const params = window.location.pathname.replace('/', '').split("/").pop();

	const alertUser = e => {
		e.preventDefault()
		e.returnValue = ''
	}

	const sendExitEvent = async () => {
		if (document.visibilityState === 'hidden')
			navigator.sendBeacon(`${url}/events`, {
				token: params,
				type: 0
			})
	}

	useEffect(() => {
		const query = new URLSearchParams(window.location.search);

		var queryUpdated = window.location.pathname;
		queryUpdated = queryUpdated.replace("/forms/", "");

		var token="";
	
		if(query.get('forms')){
			token = query.get('forms');
		} else {
			token = queryUpdated
		}
		// setScript(resource.questions.map(item => ({
		// 	...item,
		// 	comments: matchComments(item.scores, item.comments)
		// })))
		// setPage(0);
		console.log("Check auth at"+`${url}/forms/${token}`);


		fetch(`${url}/forms/${token}`)
			.then(res => res.json())
			.then(res => {
				// setPage(-3);

				if (res.error) {
					if (res.error.status == 1) {
						setPage(-2);
					}
					else {
						setPage(-3);
					}

				}
				else {
					setPage(0);
					setScript(!res.error && (res.data.survey.questions.map(item => ({
						...item,
						comments: matchComments(item.scores, item.comments)
					})))
					);
				}

			})
			.catch(() => setPage(-3))
	}, []);

	const submitSurvey = () => {
		const query = new URLSearchParams(window.location.search);
		let body = new URLSearchParams();

		var queryUpdated = window.location.pathname;
		queryUpdated = queryUpdated.replace("/forms/", "");

		var token="";
	
		if(query.get('forms')){
			token = query.get('forms');
		} else {
			token = queryUpdated
		}
		
		body.append('token', token);
		body.append('question_1_answer', encodeURIComponent(response[0]));
		body.append('question_2_answer', encodeURIComponent(response[1]));
		body.append('question_3_answer', encodeURIComponent(response[2]));
		body.append('question_4_answer', encodeURIComponent(response[3]));
		body.append('question_5_answer', encodeURIComponent(response[4]));
		body.append('question_6_answer', encodeURIComponent(response[5]));
		fetch(`${url}/survey-instances`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: body

		})
			.then(res => res.json())
			.then(res => {
				setPage(2);
				// console.log(response);
			})
			.catch(e => setPage(2))

	}

	useEffect(() => {
		if (warnExit) {
			window.addEventListener('beforeunload', alertUser)
			window.addEventListener('visibilitychange', sendExitEvent)
		}

		return () => {
			if (warnExit) {
				window.removeEventListener('beforeunload', alertUser)
				window.removeEventListener('visibilitychange', sendExitEvent)
			}
		}
	}, [warnExit])

	// basically, this function matches the comment with the score
	const matchComments = (score, comments) => {
		let scoreArr = score.split(",[").map(item => item.replace(']', '').replace('[', '').split(','));
		return [1, 2, 3, 4, 5].map(num => comments[scoreArr.findIndex(subArr => subArr.find(item => item == num))]);
	}

	// set display script
	// const script = resource.questions.map(item => ({
	// 	...item,
	// 	comments: matchComments(item.scores, item.comments)
	// }))

	const enterQuiz = () => {
		setPage(1);
		setWarnExit(true);
	}

	const submitQuestion = e => {
		console.log(currSliderVal);
		if (index < script.length) {
			// console.log(response);
			setResponse([...response, parseInt(currSliderVal)]);
			setProgress(100 / script.length * (index + 1));
			setShowNextButton(true);
		}
	}

	const nextQuestion = e => {
		if (index < script.length) {
			setShowNextButton(false);
			setEnableSubmit(false);
			setCurrSliderVal(-1);
			setHideThumb(true);

			// next question
			if (index < script.length - 1)
				setIndex(index + 1);

			// end of the quiz, submit it
			else {
				setWarnExit(false);
				// console.log(response)
				// console.log(currSliderVal);
				// setResponse([...response, parseInt(currSliderVal)]);
				console.log(response);
				submitSurvey();
				// fetch(`${url}/survey-instances`, {
				// 	method: 'POST',
				// 	headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
				// 	body: 
				// 		JSON.stringify({
				// 		token: params,
				// 		question_1_answer: response[0],
				// 		question_2_answer: response[1],
				// 		question_3_answer: response[2],
				// 		question_4_answer: response[3],
				// 		question_5_answer: response[4],
				// 		question_6_answer: response[5],})

				// })
				// 	.then(res => res.json())
				// 	.then(res => {
				// 		setPage(2);
				// 		// console.log(response);

				// 	})
			}
		}
	}

	const submitTooltip = (props) => currSliderVal ? <div /> : <Tooltip {...props}>Please select your score to continue</Tooltip>

	return (
		<div id="main">
			<Navbar bg="light" variant="light">
				<Navbar.Brand><img src={companyLogo} className="banner-logo" alt="GetPrepGo logo" /></Navbar.Brand>
			</Navbar>
			<Container className="App">
				{/* FOR WHEN USER TOKEN IS NOT VALID */}
				{page === -3 && <div className='first-screen'>
					<div>You are not authorized to access this website</div>
					{/* <div>If you believe this is an error, please contact us at [PLACEHOLDER EMAIL]</div> */}
				</div>}

				{/* FOR WHEN THE USER TOKEN IS EXPIRED OR NO LONGER VALID (LIKE THEY HAVE ANSWERED THE QUESTION ALREADY?) */}
				{page === -2 && <div className='first-screen'>
					<div>Thanks for previously completing this survey. Your information was recorded and there is no need for further action.</div>
					{/* <div>If you believe this is an error, please contact us at [PLACEHOLDER EMAIL]</div> */}
				</div>}

				{/* WELCOME SCREEN */}
				{page === 0 && <div className='first-screen'>
					<div>This survey is short and will only take a few minutes of your time. When complete, it's sent to your job coach so they can offer you more personalised support. Your honest answers help make this support relevant to you. It has no impact on payments.</div>
					<Button onClick={enterQuiz}>Begin</Button>
				</div>}

				{/* QUIZ SCREEN */}
				{page === 1 && index < script.length && <div className='second-screen'>
					<div className='input-box'>
						{/* <ProgressBar className='app-progress-bar' now={progress} srOnly /> */}
						<div className='large-main-text'>{script[index].question}</div>
						{/* <div className={hideThumb ? 'slider-hide' : ''}> */}
						<div>
							<span className='range-slider__wrap range-slider__wrap--lg'>
								<input type='range'
									className={`${currSliderVal < 0 ? 'slider-hide' : 'slider-visible'} slider range-slider range-slider--lg range-slider--primary custom-range-sider ${showNextButton && 'disabled'}`}
									size="lg" tooltip="hide" tooltipPlacement="top"
									value={currSliderVal}
									onChange={e => {
										setCurrSliderVal(e.target.value);
										setEnableSubmit(true);
									}}
									onClick={e => {
										// if (hideThumb) {
											setHideThumb(false);
										// }
									}}
									min={1} max={5}
									disabled={showNextButton} />
								<div className={`range-slider__tooltip range-slider__tooltip--auto range-slider__tooltip--top ${showNextButton && 'range-slider__tooltip--disabled'}`} style={{ left: `calc(${(currSliderVal - 1) * 25}% - ${(currSliderVal - 3) * 6}px)` }}>
									<div className="range-slider__tooltip__label">{currSliderVal}</div>
									<div className="range-slider__tooltip__arrow"></div>
								</div>
							</span>
						</div>

						{/* <span className='range-slider__wrap range-slider__wrap--lg'>
							<input type='range'
								className={`slider range-slider range-slider--lg range-slider--primary custom-range-sider ${showNextButton && 'disabled'}`}
								size="lg" tooltip="auto" tooltipPlacement="top"
								value={currSliderVal}
								onChange={e => { setCurrSliderVal(e.target.value); setEnableSubmit(true); }}
								onClick={e => { setCurrSliderVal(e.target.value); setEnableSubmit(true); }}
								min={1} max={5}
								disabled={showNextButton} />
							<div className={`range-slider__tooltip range-slider__tooltip--auto range-slider__tooltip--top ${showNextButton && 'range-slider__tooltip--disabled'}`} style={{ left: `calc(${(currSliderVal - 1) * 25}% - ${(currSliderVal - 3) * 10}px)` }}>
								<div className="range-slider__tooltip__label">{currSliderVal}</div>
								<div className="range-slider__tooltip__arrow"></div>
							</div>
						</span> */}


						<Row>
							<Col xs={5} lg={5} className='label-text left'>{script[index].lowest}</Col>
							<Col xs={2} lg={2} />
							<Col xs={5} lg={5} className='label-text right'>{script[index].highest}</Col>
						</Row>
					</div>


					<div className={`description-box ${showNextButton && 'show'}`}>
						<div className={`large-main-text large-main-comment ${showNextButton && 'show'}`}>{script[index].comments[currSliderVal - 1]}</div>
						<Row>
							<p>&nbsp;</p>
						</Row>
					</div>
					{showNextButton ?
						<Button className='white-next-button red-next-button' onClick={nextQuestion}>{index != script.length -1 ? "Next" : "End Survey"} <ChevronRight style={{ fontWeight: 800, marginRight:'-12px', fontSize: '1.25em' }} className='align-icons' /><ChevronRight style={{ fontWeight: 800, fontSize: '1.25em' }} className='align-icons' /></Button> :

						<OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={submitTooltip}>
							<span className="d-inline-block">
								<Button style={{ pointerEvents: currSliderVal ? 'initial' : 'none' }} className='white-next-button submit-button' onClick={submitQuestion} disabled={!enableSubmit}>Submit</Button>
							</span>
						</OverlayTrigger>}
				</div>}

				{/* THANK YOU SCREEN */}
				{page === 2 && <div id="last-screen">
					<div id="thank-you-label">
						<h3>THANKS.</h3>
						<div className='large-main-text center'>Here is what happens next</div>
					</div>
					<div className='left' id="thank-you-text">
						<p>1. You receive an email confirming survey completion. It includes some handy tips for job seeking that are based on your answers.</p>
						<p>2. Your job coach receives an email confirming survey completion. It helps them design support to suit you.</p>
					</div>
					{/* <div>Response: {JSON.stringify(response)}</div> */}
				</div>}

			</Container>
		</div>
	);
}

export default App;

